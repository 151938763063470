import { defineSetupVue3 } from '@histoire/plugin-vue'
import SlotPlaceholder from './src/histoire/components/SlotPlaceholder.vue'
import Grid from './src/components/Grid/Grid.vue'
import Flex from './src/components/Flex/Flex.vue'
import './src/styles/variables.scss'
import './src/histoire/style.scss'

export const setupVue3 = defineSetupVue3(async ({ app, story, variant }) => {
  app.component('SlotPlaceholder', SlotPlaceholder)
  app.component('Grid', Grid)
  app.component('Flex', Flex)
})
