import { Property } from 'csstype'

export function isPromise(value: any) {
	if (!value || typeof value !== 'object') return false
	return typeof value.then === 'function' && typeof value.catch === 'function'
}

export const throttle = <R, A extends any[]>(
	fn: (...args: A) => R,
	delay: number
): [(...args: A) => R | undefined, () => void] => {
	let wait = false
	let timeout: undefined | number
	let cancelled = false

	return [
		(...args: A) => {
			if (cancelled) return undefined
			if (wait) return undefined

			const val = fn(...args)

			wait = true

			timeout = window.setTimeout(() => {
				wait = false
			}, delay)

			return val
		},
		() => {
			cancelled = true
			clearTimeout(timeout)
		},
	]
}

const SizePresets = [
	'xxs',
	'xs',
	'sm',
	'default',
	'lg',
	'xl',
	'xxl',
	'3xl',
	'4xl',
] as const

export type SizePreset = (typeof SizePresets)[number]
export type Size = `${string}${'px' | 'rem' | 'em' | '%' | 'vw' | 'vh'}`

export const getCSSVariable = (type: string, v: string) => {
	if (v === 'default') return `var(--k-ui-${type})`
	return `var(--k-ui-${type}-${v})`
}

export const getAlignment = (
	v?: Property.JustifyContent | Property.AlignItems
) => {
	switch (v) {
		case 'start':
		case 'end':
			return `flex-${v}`
		default:
			return v
	}
}

export type SizePresetOrFallback = Size | `${Size} ${Size}` | SizePreset
export const getSizePresetOrFallback = (
	v: SizePresetOrFallback,
	type: string = 'spacing'
) => {
	const values = v.split(' ')
	return values
		.map(value => {
			if (
				typeof value === 'number' ||
				(typeof value === 'string' && value.match(/^\d+$/))
			)
				return `${value}rem`
			if (
				typeof value === 'string' &&
				SizePresets.includes(value as SizePreset)
			)
				return getCSSVariable(type, value as SizePreset)
			return value
		})
		.join(' ')
}

export function generateUUID() {
	var d = new Date().getTime() //Timestamp
	var d2 =
		(typeof performance !== 'undefined' &&
			performance.now &&
			performance.now() * 1000) ||
		0
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = Math.random() * 16
		if (d > 0) {
			r = (d + r) % 16 | 0
			d = Math.floor(d / 16)
		} else {
			r = (d2 + r) % 16 | 0
			d2 = Math.floor(d2 / 16)
		}
		return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
	})
}

export type LoadScriptOptions = { type: string }
export function LoadScript(
	src: string,
	options: LoadScriptOptions = { type: 'text/javascript' }
) {
	if (!document) return
	if (document.querySelector(`script[src="${src}"]`)) return

	let { type } = options

	let script = document.createElement('script')
	script.type = type
	script.src = src

	document.body.appendChild(script)
}

export function toArray<T>(array?: T | T[] | null | undefined): T[] {
	array = array ?? []
	return Array.isArray(array) ? array : [array]
}

export const toCSSVariables = (name: string, vars: Record<string, string>) => {
	return Object.fromEntries(
		Object.entries(vars).map(([k, v]) => [`--k-${name}-${k}`, v])
	)
}

export function Debounce<T extends (...args: any[]) => void>(
	func: T,
	wait: number,
	immediate = false
) {
	let timeout: ReturnType<typeof setTimeout> | null

	return function <U>(this: U, ...args: Parameters<typeof func>) {
		const context = this
		const later = () => {
			timeout = null

			if (!immediate) func.apply(context, args)
		}

		const callNow = immediate && !timeout

		if (typeof timeout === 'number') clearTimeout(timeout)

		timeout = setTimeout(later, wait)
		if (callNow) func.apply(context, args)
	}
}
